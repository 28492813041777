import { RouteRecordRaw } from 'vue-router'
import { Feature } from '@/api/useCompany.types'
import { createRootPath } from '@/entrypoints/utils/routerUtils'
import { Route } from '../base/config/routesConfig'

export const routes: RouteRecordRaw[] = [
  {
    path: createRootPath('footprint'),
    name: Route.Footprint,
    redirect: { name: Route.FootprintEntry },
    meta: {
      requireFeatures: [Feature.Footprint],
    },
    children: [
      {
        path: 'data-entry',
        name: Route.FootprintEntry,
        redirect: { name: Route.FootprintEntryDefault },
        children: [
          {
            path: '',
            name: Route.FootprintEntryDefault,
            component: () =>
              import('@/modules/footprint/views/FootprintEntry.vue'),
          },
          {
            path: ':footprintId',
            name: Route.FootprintEntryYear,
            redirect: { name: Route.FootprintEntryYearDefault },
            meta: {
              companySwitchRedirect: { name: Route.FootprintEntry },
            },
            children: [
              {
                path: '',
                name: Route.FootprintEntryYearDefault,
                component: () =>
                  import('@/modules/footprint/views/FootprintEntryYear.vue'),
              },
              {
                path: ':dataPointId',
                name: Route.FootprintEntryYearPoint,
                component: () =>
                  import(
                    '@/modules/footprint/views/FootprintEntryYearPoint.vue'
                  ),
                meta: {
                  companySwitchRedirect: { name: Route.FootprintEntry },
                },
              },
              {
                path: 'data-room',
                name: Route.FootprintDataRooms,
                redirect: { name: Route.FootprintEntryDefault },
                children: [
                  {
                    path: ':dataRoomId',
                    name: Route.FootprintDataRoom,
                    component: () =>
                      import('@/modules/footprint/views/FootprintDataRoom.vue'),
                    meta: {
                      companySwitchRedirect: { name: Route.FootprintEntry },
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'result',
        name: Route.FootprintResult,
        component: () =>
          import('@/modules/footprint/views/FootprintResult.vue'),
      },
      {
        path: 'activities',
        name: Route.FootprintActivities,
        component: () =>
          import('@/modules/footprint/views/FootprintActivities.vue'),
      },
    ],
  },
]
